@import "../../../styles/base/abstracts";

.hbs-cta-card {
  @include grid-parent($offset: true);

  &.hbs-cta-card--has-art {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  @include mq($bp-tablet) {
    display: flex;

    .hbs-global-align-full &,
    .hbs-global-align-wide & {
      @include grid-parent($offset: true);
      @include margin(x 0);

      &--media-left {
        flex-direction: row-reverse;
      }
    }
  }
}

.hbs-cta-card__content,
.hbs-cta-card__asset {
  @include grid-child;
}

.hbs-cta-card__asset {
  .hbs-kaltura-embed {
    button {
      &:focus:not(:hover) {
        // padding + margin offset to account for tabbing focus outline
        outline-offset: -8px !important;
      }
    }
  }
}

.hbs-cta-card--media-right,
.hbs-cta-card--media-left {
  .hbs-global-align-full & {
    @include mq(max, $bp-tablet) {
      .hbs-cta-card__art {
        margin-top: $spacing-sm;
      }
    }
  }
}

// Card
.hbs-cta-card__inner {
  @include padding($grid-padding-mobile * 2);
  display: flex;
  flex-direction: column;
  height: 100%;

  .hbs-cta-card--has-art:not(.hbs-cta-card--no-image-gap) & {
    min-height: $card-min-height;
  }

  @include mq($bp-tablet) {
    @include padding($grid-padding * 2);
  }
}

.hbs-cta-card__overline {
  @include small-title;
  margin-bottom: $spacing-sm;
}

.hbs-cta-card {
  .hbs-cta-card__title {
    @include h3;
    margin-bottom: $spacing-sm;
    max-width: 22em;
  }

  .hbs-global-align-center & .hbs-cta-card__title,
  &.hbs-cta-card--has-art .hbs-cta-card__title {
    @include mq($bp-tablet) {
      @include h4;
    }
  }
}

.hbs-cta-card__subtitle {
  @include body-text($ff-sans);
  color: $c-text-light;
  max-width: 30em;
}

.hbs-cta-card__cta {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  padding-top: $spacing-xxl - $spacing-md;
  row-gap: $spacing-sm;

  .hbs-cta-list {
    width: 100%;
  }

  .hbs-cta-link--primary-button,
  .hbs-cta-link--secondary-button {
    margin-right: $spacing-xl;
    margin-top: $spacing-md;
  }
}

// Art
.hbs-cta-card__art {
  @include size(100%, auto);
  display: flex;
  overflow: hidden;
  position: relative;

  @include mq($bp-tablet) {
    width: 50%;
  }
}

.hbs-cta-card .hbs-cta-card__asset {
  @include size(100%, auto);
  @include absolute(0);

  .hbs-media-asset__kaltura-text {
    @include size(auto, auto);
  }

  @include mq(max, $bp-desktop-lg) {
    &:not(:first-child) {
      display: none;
    }
  }

  *:not(button):not(svg) {
    @include size(100%);
    object-fit: cover;
  }
}

// Art Count
.hbs-cta-card--has-art {
  @include mq($bp-tablet) {
    .hbs-cta-card__content {
      width: 50%;
    }
  }

  @include mq($bp-desktop-lg) {
    .hbs-media-asset__wrapper {
      clip-path: inset(0);

      img,
      video {
        @include absolute(0);
      }
    }

    .hbs-cta-card__asset {
      position: relative;
    }
  }
}

.hbs-cta-card--art-count-2 {
  @include mq($bp-desktop-lg) {
    .hbs-cta-card__content {
      width: 50%;
    }

    .hbs-cta-card__art {
      width: 50%;
    }

    .hbs-global-align-center & {
      .hbs-cta-card__asset {
        &:last-child {
          display: none;
        }
      }
    }
  }
}

.hbs-cta-card--art-count-3 {
  @include mq($bp-desktop-lg) {
    .hbs-cta-card__content {
      width: $col-one-third;
    }

    .hbs-cta-card__art {
      width: $col-one-third * 2;
    }

    .hbs-cta-card__asset {
      &:last-child {
        display: none;
      }
    }

    .hbs-global-align-wide &,
    .hbs-global-align-center & {
      .hbs-cta-card__content {
        width: 50%;
      }

      .hbs-cta-card__art {
        width: 50%;
      }
    }

    .hbs-global-align-center & {
      .hbs-cta-card__asset {
        &:last-child,
        &:nth-last-child(2) {
          display: none;
        }
      }
    }
  }

  @include mq($bp-desktop-xl) {
    .hbs-cta-card__content {
      width: $col-5;
    }

    .hbs-cta-card__title {
      @include h4;
    }

    .hbs-cta-card__art {
      width: $col-11;
    }

    .hbs-cta-card__asset {
      &:last-child {
        display: block;
      }
    }

    .hbs-global-align-wide & {
      .hbs-cta-card__asset {
        &:last-child {
          display: none;
        }
      }
    }
  }
}

// Email
// .hbs-cta-card--email,
.hbs-cta-card--no-image-gap {
  .hbs-cta-card__content {
    order: 2;

    @include mq($bp-tablet) {
      order: 1;
    }
  }

  .hbs-cta-card__art {
    @include padding($grid-padding-mobile * 2);
    order: 1;

    @include mq($bp-tablet) {
      @include padding($grid-padding * 2);
    }
  }

  .hbs-media-asset__wrapper {
    clip-path: inset(0);

    img,
    video {
      @include absolute(0);
    }
  }

  .hbs-cta-card__asset {
    position: relative;
  }
}
