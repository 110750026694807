@import "../../../styles/base/abstracts";

.hbs-newsletter-signup--success {
  opacity: 0;
  animation: fadeUp $t-time-md $t-ease-out forwards;
}

.hbs-newsletter-signup__success-container {
  margin: $font-margin-h6;
}

.hbs-newsletter-signup__input[type="email"] {
  .hbs-form & {
    background-color: $c-white;
    color: $c-black;

    &::placeholder {
      color: $c-black;
    }

    [data-theme="light"] &,
    [data-theme="white"] & {
      border-color: $c-border-light;
    }
  }
}

.hbs-newsletter-signup__input-container {
  position: relative;
  margin-top: -$spacing-sm;
}

.hbs-newsletter-signup__submit {
  @include absolute(50% $spacing-sm x x);
  @include size(1.4em);
  align-items: center;
  background-color: $c-spot;
  border-radius: 50%;
  display: flex;
  margin-top: -0.7em;
  justify-content: center;

  [data-theme="crimson"] & {
    background-color: $c-bg;
  }

  [data-theme="dark"] &,
  [data-theme="black"] & {
    background-color: $c-crimson;
  }

  [data-theme="purple"] &,
  [data-theme="blue"] &,
  [data-theme="red"] & {
    background-color: $c-charcoal;
  }
}

.hbs-newsletter-signup__icon {
  @include size(0.6em);
  color: $c-theme-solid-inverse;

  [data-theme="crimson"] & {
    color: $c-text;
  }

  [data-theme="dark"] &,
  [data-theme="black"] & {
    color: $c-text;
  }
  [data-theme="purple"] &,
  [data-theme="blue"] &,
  [data-theme="red"] & {
    color: $c-cream;
  }
}

.hbs-newsletter-signup__icon--success {
  @include size(0.7em);
}

.hbs-newsletter-signup__title {
  @include body-title;
}

.hbs-newsletter-signup__subtitle {
  @include baseline-text;
}

.hbs-newsletter-signup__success-indicator {
  @include size(1.4em);
  align-items: center;
  background-color: $c-green;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  float: left;
  margin-top: $spacing-xxs;

  opacity: 0;
  animation: pop $t-time-sm $t-ease-out forwards 0.2s;
  backface-visibility: hidden;
}

.hbs-newsletter-signup__success-message {
  float: left;
  padding-left: $spacing-xs;
  width: calc(100% - 1.4em);
}
